<template>
  <main class="mt-16 md:mt-6 xl:mt-16 w-full">
    <section
      class="max-w-screen-md px-6 lg:max-w-screen-lg xl:max-w-screen-xl xl:px-12 2xl:max-w-screen-2xl 2xl:px-24 mx-auto"
    >
      <div
        class="border border-grey-100 w-full md:flex items-stretch justify-between"
      >
        <div class="hidden md:block md:w-1/2">
          <img
            src="../assets/register.jpg"
            alt=""
            class="w-full object-cover h-full"
          />
        </div>
        <div class="md:w-1/2 px-4 lg:px-10 py-6 lg:py-14">
          <form action="#">
            <p class="text-grey-900 text-2xl font-bold text-center">Register</p>

            <div class="mt-12">
              <label for="tel">
                <span class="sr-only">tel</span>
                <input
                  v-model="registerInfo.phoneNo"
                  id="tel"
                  type="tel"
                  class="mt-6 border-b border-grey-500 w-full pb-3 focus:border-primary-black focus:outline-none"
                  placeholder="Phone Number"
                />
              </label>

              <div class="mt-10 flex items-center space-x-8">
                <label for="First Name" class="w-full">
                  <span class="sr-only">First Name</span>
                  <input
                    v-model="registerInfo.firstName"
                    id="First Name"
                    type="text"
                    class="border-b border-grey-500 w-full pb-3 focus:border-primary-black focus:outline-none"
                    placeholder="First Name"
                  />
                </label>
                <label for="Last Name" class="w-full">
                  <span class="sr-only">Last Name</span>
                  <input
                    v-model="registerInfo.lastName"
                    id="Last Name"
                    type="text"
                    class="border-b border-grey-500 w-full pb-3 focus:border-primary-black focus:outline-none"
                    placeholder="Last Name"
                  />
                </label>
              </div>

              <label for="email">
                <span class="sr-only">email</span>
                <input
                  v-model="registerInfo.email"
                  id="email"
                  type="email"
                  class="mt-10 border-b border-grey-500 w-full pb-3 focus:border-primary-black focus:outline-none"
                  placeholder="Email Address"
                />
              </label>

              <label for="password">
                <span class="sr-only">password</span>
                <input
                  v-model="registerInfo.password"
                  id="password"
                  type="password"
                  class="mt-10 border-b border-grey-500 w-full pb-3 focus:border-primary-black focus:outline-none"
                  placeholder="Password"
                />
              </label>
            </div>
             <div class="mt-10 flex items-center space-x-8">
                <label for="Country" class="w-full">
                  <span class="sr-only">Country</span>
                  <input
                    v-model="registerInfo.country"
                    id="country"
                    type="text"
                    class="border-b border-grey-500 w-full pb-3 focus:border-primary-black focus:outline-none"
                    placeholder="country"
                  />
                </label>
                <label for="City" class="w-full">
                  <span class="sr-only">City</span>
                  <input
                    v-model="registerInfo.city"
                    id="city"
                    type="text"
                    class="border-b border-grey-500 w-full pb-3 focus:border-primary-black focus:outline-none"
                    placeholder="city"
                  />
                </label>
              </div>

                           <div class="mt-10 flex items-center space-x-8">

                <label for="Address" class="w-full">
                  <span class="sr-only">Address</span>
                  <input
                    v-model="registerInfo.address"
                    id="Address"
                    type="text"
                    class="border-b border-grey-500 w-full pb-3 focus:border-primary-black focus:outline-none"
                    placeholder="Address"
                  />
                </label>
                                    </div>

            <button
              @click.prevent="Register"
              type="submit"
              class="mt-12 w-full bg-primary-black block uppercase text-surface-white py-3 rounded"
            >
              Register
            </button>
            <p class="text-grey-300 text-xs mt-2 text-center">
              By clicking “Register” you are agreeing our “Terms & Conditions”
            </p>
          </form>
        </div>
      </div>

      <div class="w-full md:flex items-stretch justify-end mt-4">
        <div class="md:w-1/2 text-center">
          <span class="text-grey-700 text-sm"> Already have an account? </span>
          <a v-if="$authenticatedUser.userId == 0"
            href="#"
            @click.prevent="Login"
            class="text-sm font-semibold text-primary-black underline"
          >
            Login
          </a> 
           <a
           v-else
            href="#"
            @click.prevent="Logout"
            class="text-sm font-semibold text-primary-black underline"
          >
            Logout
          </a>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  created() {
    this.SetRules();
  },
  data() {
    return {
      registerInfo: {
        firstName: null,
        lastName: null,
        phoneNo: null,
        email: null,
        password: null,
        country  : null,
        city  : null,
        address : null,
      },
      rules: null,
      errors: [],
      loding: false,
    };
  },
  methods: {
    Register() {
      this.loding = true;
      this.$http.customersService
        .Register(this.registerInfo)
        .then((res) => {
          this.loding = false;
          let data = res.data;
          if (data.statusCode === undefined || data.statusCode !== 0) {
            this.$globalAlert.notify(
              "تعذر الاتصال بالخادم، الرجاء المحاولة لاحقا",
              "warning"
            );

            return;
          }
          this.$router.replace("/login");
        })
        .catch((err) => {
          this.loding = false;
          if (
            err.response.data.statusCode !== null &&
            err.response.data.statusCode !== undefined
          )
            this.$globalAlert.alert(
              err.response.data.result +
                " [" +
                err.response.data.statusCode +
                "] ",
              "error"
            );
          else
            this.$globalAlert.alert(
              "تعذر الاتصال بالخادم، الرجاء المحاولة لاحقا",
              "error"
            );
        });
    },
    Login() {
      this.$router.replace("/login");
    },  
 
   Logout() {
            //this.$blockUI.Start();
            //   this.profile = false;
            //  this.loding = true;
            this.$http
                .Logout()
                .then((res) => {
                    let data = res.data;
                    if (data.statusCode === 0) {
                        this.$authenticatedUser.userId = 0;
                        this.$authenticatedUser.fullName = "";
                        this.$authenticatedUser.userName = "";
                        this.$authenticatedUser.userPermissions = [];
                        // this.state = 1;

                        this.$router.replace("/login");

                        //this.$blockUI.Stop();
                    } else {
                        //this.$blockUI.Stop();
                    }
                })
                .catch(() => {
                    //this.$blockUI.Stop();
                });
        },
    SetRules() {
      this.rules = {};
    },
  },
};
</script>

<style>
</style>